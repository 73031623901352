@media screen and (min-width: 1200px) {
    .recommendations-column {
        max-width: 400px;
    }
}

.vjs-poster {
    background-size: cover !important;
    
}
