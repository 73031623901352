.uploader-title-link {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    word-break: break-word;
    display: -webkit-box;
    overflow: hidden;
    margin-bottom: 0;

    h5 {
        margin-bottom: 0.1em;
    }
}
